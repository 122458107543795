import { Application } from '@splinetool/runtime';

const canvas = document.getElementById('hero-image-3d');

const app = new Application(canvas);
app.load('https://prod.spline.design/p8D73mm7-xKTO8qK/scene.splinecode');

document.addEventListener('click', (event) => {
    // Check if the clicked element or its parent has `data-toggle-rotation`
    if (event.target.matches('[data-toggle-rotation]') || event.target.closest('[data-toggle-rotation]')) {
        const row = event.target.closest('[data-toggle-rotation]');
        row.classList.toggle('clicked');
    }
});

window.scrollToSection = function (section) {
    document.querySelector(`#${section}`).scrollIntoView({
        behavior: "smooth",
        block: "start"
    });
};

window.joinDiscord = function () {
    window.open("https://discord.gg/orangefinventures", "_blank");
};

window.toggleSubmenu = function () {
    const submenu = document.getElementById("submenu");
    const navLinkMore = document.getElementById("nav-link-more");
    submenu.style.display = submenu.style.display === "flex" ? "none" : "flex";

    // Toggle the rotation class on #nav-link-more
    navLinkMore.classList.toggle("submenu-open");
};

window.toggleSubmenuMobile = function () {
    const submenu = document.getElementById("submenu-mobile");
    const navLinkMore = document.getElementById("nav-link-more-mobile");
    submenu.style.display = submenu.style.display === "flex" ? "none" : "flex";

    // Toggle the rotation class on #nav-link-more
    navLinkMore.classList.toggle("submenu-open");
};

window.prices = {};

window.fetchSolanaData = function fetchSolanaData() {
    fetch('https://api.stakewiz.com/validator/oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe')
        .then(res => res.json())
        .then(res => {

            const apy = document.querySelectorAll("#solana-apy");
            if (apy != null) {
                document.querySelector("#solana-apy").textContent = res.total_apy + "%";
            }

            const stake = document.querySelectorAll("#solana-stake");
            if (stake != null) {
                document.querySelector("#solana-stake").textContent = formatNumber(res.activated_stake) + " SOL";
            }

            const stake_usd = document.querySelectorAll("#solana-stake-usd");
            if (stake_usd != null) {
                document.querySelector("#solana-stake-usd").textContent = `$${formatNumber(res.activated_stake * prices.sol)}`
            }
        });
};

window.fetchGnosisData = function fetchGnosisData() {
    const stakewise = require('@stakewise/v3-sdk');
    const { StakeWiseSDK, Network } = stakewise;
    const gnosisSDK = new StakeWiseSDK({ network: Network.Gnosis });
    gnosisSDK.vault.getVault({ vaultAddress: "0xe32773a0f6fa8ebf2a32c040dacd031a1ad1e530" })
        .then(res => {
            const total_stake = parseFloat(res.totalAssets).toFixed(2);
            const stake = document.querySelectorAll("#gnosis-stake");
            if (stake != null) {
                document.querySelector("#gnosis-stake").textContent = formatNumber(total_stake) + " GNO";
            }

            const stake_usd = document.querySelectorAll("#gnosis-stake-usd");
            if (stake_usd != null) {
                document.querySelector("#gnosis-stake-usd").textContent = `$${formatNumber(total_stake * prices.gno)}`
            }

            const apy_estimate = res.apy.toFixed(2);
            const apy = document.querySelectorAll("#gnosis-apy");
            if (apy != null) {
                document.querySelector("#gnosis-apy").textContent = apy_estimate + "%";
            }
        });
};

window.fetchSolanaAssetsUnderDelegation = function fetchSolanaAssetsUnderDelegation() {
    return new Promise((resolve, reject) => {
        fetch("https://validators-api.marinade.finance/validators?limit=1&query_vote_accounts=oRAnGeU5h8h2UkvbfnE5cjXnnAa4rBoaxmS4kbFymSe")
            .then(res => res.json())
            .then(res => {
                resolve(parseFloat(res.validators[0].activated_stake) / 1e9);
            })
    })
};


window.fetchGnosisAssetsUnderDelegation = function fetchGnosisAssetsUnderDelegation() {
    return new Promise((resolve, reject) => {
        const stakewise = require('@stakewise/v3-sdk');
        const { StakeWiseSDK, Network } = stakewise;
        const gnosisSDK = new StakeWiseSDK({ network: Network.Gnosis });
        gnosisSDK.vault.getVault({ vaultAddress: "0xe32773a0f6fa8ebf2a32c040dacd031a1ad1e530" })
            .then(res => {
                const total_stake = parseFloat(res.totalAssets).toFixed(2);
                resolve(parseFloat(total_stake));
            })
    })
};

window.fetchExchangeRates = function fetchExchangeRates() {
    return new Promise((resolve, reject) => {
        Promise.all([
            fetch("https://api.kraken.com/0/public/Ticker?pair=SOLUSD"),
            fetch("https://api.kraken.com/0/public/Ticker?pair=ETHUSD"),
            fetch("https://api.kraken.com/0/public/Ticker?pair=GNOUSD")
        ])
            .then(responses => Promise.all(responses.map(response => response.json())))
            .then(data => {
                const sol = parseFloat(data[0].result.SOLUSD.c[0]);
                const eth = parseFloat(data[1].result.XETHZUSD.c[0]);
                const gno = parseFloat(data[2].result.GNOUSD.c[0]);
                resolve({ sol, eth, gno });
            })
            .catch(error => reject(error));
    });
};

window.fetchAssetsUnderDelegation = function fetchAssetsUnderDelegation() {
    return new Promise((resolve, reject) => {
        Promise.all([
            fetchExchangeRates(),
            fetchSolanaAssetsUnderDelegation(),
            fetchGnosisAssetsUnderDelegation(),
        ])
            .then(data => {
                const aud = (data[1] * data[0].sol) + (data[2] * data[0].gno);
                // This is a hack to ensure a weird bug doesn't pop up where it does not show a number
                if (aud > 40000) {
                    window.numAnim.update(aud);
                }
                resolve(aud.toFixed(2));
            })
            .catch(e => {
                console.log(e);
                window.numAnim.update(100000000);
            });
    })
};

window.formatNumber = function formatNumber(number) {
    if (isNaN(number)) {
        throw new Error("Input must be a number");
    }

    if (number < 1e3) {
        if (typeof number === "string") {
            return number;
        }
        return (number.toFixed(2)); // less than 1000
    } else if (number < 1e6) {
        return (number / 1e3).toFixed(1) + 'K'; // less than 1 million
    } else if (number < 1e9) {
        return (number / 1e6).toFixed(1) + 'M'; // less than 1 billion
    } else {
        return (number / 1e9).toFixed(1) + 'B'; // 1 billion or more
    }
};

window.formatDollar = function (number) {
    return `$${formatNumber(number)}`;
}

window.animateAud = function animateAud() {
    const numAnim = new countUp.CountUp(
        'aum',
        '118000000',
        {
            formattingFn: window.formatDollar,
            duration: 5,
        }
    );
    window.numAnim = numAnim;
    numAnim.start();
};


window.fetchExchangeRates()
    .then(res => {
        prices = res;
        window.fetchAssetsUnderDelegation();
        window.fetchSolanaData();
        window.fetchGnosisData();
    });

window.animateAud();